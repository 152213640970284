<template>
    <div class="page-cont">
        <div class="infoCheck">
            <h2>旅游意外险投保确认书</h2>
            <el-row :gutter="4" class="form-ipt">
                <template v-if="companyid != 46">
                    <el-col :span="6">
                        <label>团号</label>
                        <el-input type="text" v-model="teamnumber"></el-input>
                    </el-col>
                    <el-col :span="6">
                        <label>计调</label>
                        <el-autocomplete
                            type="text"
                            v-model="operator"
                            :fetch-suggestions="nameSearch"
                            placeholder="输入姓名"
                        ></el-autocomplete>
                    </el-col>
                    <el-col :span="6">
                        <label>导游</label>
                        <el-input type="text" v-model="guide"></el-input>
                    </el-col>
                </template>
                <el-col :span="5">
                    <label>
                        目的地
                        <em>*</em>
                    </label>
                    <el-input type="text" v-model="destination"></el-input>
                </el-col>

                <el-col :span="5">
                    <label>
                        生效
                        <em>*</em>
                    </label>
                    <el-date-picker
                        v-model="starttime"
                        @change="dateCheck"
                        :editable="false"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="选择生效日期"
                    ></el-date-picker>
                    <!-- <el-col :span="12">
                        <el-date-picker v-model="endtime" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" type="date" placeholder="选择结束日期">
                        </el-date-picker>
                    </el-col>-->
                </el-col>
                <el-col :span="3">
                    <label>
                        天数
                        <em>*</em>
                    </label>
                    <el-input-number
                        :controls="false"
                        v-model="day"
                        @change="dayChange"
                        :min="1"
                        label="保险天数"
                    ></el-input-number>
                </el-col>
                <el-col :span="5">
                    <label>终止</label>
                    <!-- <span>{{ finalDate }}</span> -->
                    <el-date-picker
                        :value="finalDate"
                        :readonly="true"
                        :editable="false"
                        disabled
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="终止日期"
                    ></el-date-picker>
                </el-col>
                <el-col :span="5">
                    <label>
                        产品
                        <em>*</em>
                    </label>
                    <el-row>
                        <el-col :span="12">
                            <el-select v-model="quality" placeholder="请选择" @change="getPolicyList">
                                <template v-if="companyid == 12">
                                    <el-option
                                        v-for="(item, index) in productsName"
                                        :value="index + 1"
                                        :key="index"
                                        :label="item"
                                    ></el-option>
                                </template>
                                <template v-else>
                                    <el-option
                                        v-for="(item, index) in productsName1"
                                        :value="index + 1"
                                        :key="index"
                                        :label="item"
                                    ></el-option>
                                </template>
                            </el-select>
                        </el-col>
                        <el-col :span="12">
                            <el-select
                                placeholder="请选择"
                                v-model="policyItem"
                                @change="setProudctName"
                            >
                                <el-option
                                    v-for="(item, index) in ProductList"
                                    :key="index + item.Policy_Name"
                                    :value="item.Policy_Name"
                                    :label="item.Policy_Name"
                                ></el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                </el-col>
                <template v-if="companyid == 46">
                    <el-col :span="5">
                        <label>出行方式</label>
                        <el-select v-model="delay" placeholder="请选择">
                            <el-option
                                :value="1"
                                label="飞机团"
                            ></el-option>
                            <el-option
                                :value="0"
                                label="其他出行方式"
                            ></el-option>
                        </el-select>
                    </el-col>
                </template>
                <el-col :span="2">
                    <el-button
                        type="primary"
                        plain
                        round
                        :disabled="!sendVal"
                        @click="sendFormData"
                    >提交</el-button>
                    <el-button type="warning" plain round @click="review">预览</el-button>
                </el-col>
            </el-row>
        </div>

        <div class="infoCheck person-info">
            <el-row class="top-row">
                <el-col :span="6">
                    <h2>
                        被保险人信息资料
                        <em>*</em>
                    </h2>
                </el-col>
                <el-col :span="18" class="insurance-btn-wrp" v-if="tabName == 'withTxt'">
                    <el-button type="default" plain @click="insuraceModel">下载被保险人Excel模板</el-button>
                    <el-upload
                        class="upload-demo"
                        action="/Ajax/upload_json.ashx?dir=file"
                        :multiple="false"
                        :show-file-list="false"
                        :on-success="insertPersons"
                        name="imgFile"
                    >
                        <el-button type="default" plain round>导入被保险人(excel)</el-button>
                    </el-upload>
                    <el-button type="default" plain round @click="addPerson">增加被保险人</el-button>
                    <el-button type="default" plain round @click="deleteAll">删除所有</el-button>
                    <el-button type="default" plain round @click="deleteCheck">删除选中</el-button>
                    <el-button type="primary" plain round @click="confirmPerson">校验</el-button>
                    <el-button type="primary" plain round @click="reviewBD('withTxt')">智能识别</el-button>
                </el-col>
                <el-col :span="18" class="insurance-btn-wrp" v-else>
                    <el-button type="default" plain @click="insuraceModel(1)">下载被保险人Excel模板</el-button>
                    <el-upload
                        class="upload-demo"
                        action="/Ajax/upload_json.ashx?dir=file"
                        :multiple="false"
                        :show-file-list="false"
                        :on-success="insertPersons"
                        name="imgFile"
                    >
                        <el-button type="default" plain round>导入被保险人(excel)</el-button>
                    </el-upload>
                    <el-button type="default" plain round @click="addOPerson">增加被保险人</el-button>
                    <el-button type="default" plain round @click="deleteAll">删除所有</el-button>
                    <el-button type="default" plain round @click="deleteCheck">删除选中</el-button>
                    <el-button type="primary" plain round @click="reviewBD('withPass')">智能识别</el-button>
                </el-col>
            </el-row>
            <div class="person-list-wrp">
                <el-tabs v-model="tabName" @tab-click="switchTab" type="card">
                    <el-tab-pane label="添加证件" name="withTxt">
                        <el-row
                            :gutter="16"
                            :class="{ 'err': errArr.includes(item.InsurancePerson_CertNo) }"
                            v-for="(item, index) in visitPersonArr"
                            :key="'visit' + index"
                        >
                            <el-col :span="9">
                                <label>被保险人姓名 {{ index + 1 }}</label>
                                <el-input
                                    type="text"
                                    v-model="item.InsurancePerson_Name"
                                    placeholder="输入被保险人姓名"
                                ></el-input>
                            </el-col>
                            <el-col :span="9">
                                <label>被保险人证件 {{ index + 1 }}</label>
                                <el-input
                                    type="text"
                                    v-model="item.InsurancePerson_CertNo"
                                    placeholder="输入被保险人身份证件号"
                                ></el-input>
                            </el-col>
                            <el-col :span="2" class="check-box-wrp">
                                <el-checkbox
                                    v-model="item.check"
                                    size="medium"
                                    @change="checkboxChange($event,index)"
                                ></el-checkbox>
                            </el-col>
                            <el-col :span="4" class="btn-delt" style="text-align:right">
                                <el-popconfirm
                                    confirm-button-text="好的"
                                    cancel-button-text="再考虑"
                                    icon="el-icon-info"
                                    icon-color="red"
                                    title="确定要删除吗？"
                                    @confirm="sendDelete(index, 0)"
                                >
                                    <el-button type="danger" plain round slot="reference">删除</el-button>
                                </el-popconfirm>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="添加护照" name="withPass">
                        <el-row
                            :gutter="16"
                            v-for="(item, index) in outPersonArr"
                            :key="'out' + index"
                        >
                            <template v-if="companyid == 12 || companyid == 46">
                                <el-col :span="4">
                                    <label>被保险人姓名 {{ index + 1 }}</label>
                                    <el-input
                                        type="text"
                                        v-model="item.InsurancePerson_Name"
                                        placeholder="输入被保险人姓名"
                                    ></el-input>
                                </el-col>
                                <el-col :span="6">
                                    <label>被保险人证件 {{ index + 1 }}</label>
                                    <el-input
                                        type="text"
                                        v-model="item.InsurancePerson_CertNo"
                                        placeholder="输入被保险人身份证件号"
                                    ></el-input>
                                </el-col>
                                <el-col :span="3">
                                    <label>被保险人性别 {{ index + 1 }}</label>
                                    <el-autocomplete
                                        type="text"
                                        v-model="item.InsurancePerson_Gender"
                                        :fetch-suggestions="querySearch"
                                        placeholder="输入被保险人性别"
                                    ></el-autocomplete>
                                </el-col>
                                <el-col :span="6">
                                    <label>被保险人生日 {{ index + 1 }}</label>
                                    <el-date-picker
                                        style="width: 100%;"
                                        v-model="item.InsurancePerson_Birthday"
                                        type="date"
                                        placeholder="选择被保险人生日日期"
                                        format="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd"
                                    ></el-date-picker>
                                </el-col>
                            </template>
                            <template v-else>
                                <el-col :span="9">
                                    <label>被保险人姓名 {{ index + 1 }}</label>
                                    <el-input
                                        type="text"
                                        v-model="item.InsurancePerson_Name"
                                        placeholder="输入被保险人姓名"
                                    ></el-input>
                                </el-col>
                                <el-col :span="9">
                                    <label>被保险人证件 {{ index + 1 }}</label>
                                    <el-input
                                        type="text"
                                        v-model="item.InsurancePerson_CertNo"
                                        placeholder="输入被保险人身份证件号"
                                    ></el-input>
                                </el-col>
                            </template>
                            <el-col :span="2" class="check-box-wrp">
                                <el-checkbox
                                    v-model="item.check"
                                    size="medium"
                                    @change="checkboxChange($event,index)"
                                ></el-checkbox>
                            </el-col>
                            <el-col :span="3" class="btn-delt" style="text-align:right">
                                <el-popconfirm
                                    confirm-button-text="好的"
                                    cancel-button-text="再考虑"
                                    icon="el-icon-info"
                                    icon-color="red"
                                    title="确定要删除吗？"
                                    @confirm="sendDelete(index, 1)"
                                >
                                    <el-button type="danger" plain round slot="reference">删除</el-button>
                                </el-popconfirm>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!-- <el-tab-pane label="图片导入" name="withImg">
                    <el-row>
                        <el-col :span="12" class="form-input-wrp">
                            <label>投保人数：</label><el-input type="number" v-model="totalperson"></el-input>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" class="form-input-wrp">
                            <label>图片地址：</label><el-input type="text" v-model="personimage"></el-input>
                        </el-col>
                        <el-col :span="12">
                            <el-upload class="upload-demo" action="/Ajax/upload_json.ashx?dir=image" :multiple="false"
                                :show-file-list="false" :on-success="setImgUrl" name="imgFile">
                                <el-button type="default" plain round>导入被保险人(图片)</el-button>
                            </el-upload>
                        </el-col>
                        <el-col style="text-align: center;">
                            <img style="width:93%;margin: 16px auto;" :src="domin + personimage" alt="" srcset="">
                        </el-col>
                    </el-row>

                    </el-tab-pane>-->
                </el-tabs>
            </div>
        </div>
        <el-dialog title="预览" :visible.sync="dilogVis" width="76%">
            <el-row :gutter="16" class="ylwrp">
                <el-col :span="12">
                    <label>旅行社名称</label>
                    {{ name }}
                </el-col>
                <template v-if="companyid != 46">
                    <el-col :span="12">
                        <label>团号</label>
                        {{ teamnumber }}
                    </el-col>
                    <el-col :span="12">
                        <label>导游姓名</label>
                        {{ guide }}
                    </el-col>
                    <el-col :span="12">
                        <label>计调</label>
                        {{ operator }}
                    </el-col>
                </template>
                <el-col :span="12">
                    <label>保险开始日期</label>
                    {{ starttime }}
                </el-col>
                <el-col :span="12">
                    <label>保险天数</label>
                    {{ day }}
                </el-col>
                <el-col :span="12">
                    <label>产品选择</label>
                    {{ Policy_Name }}
                </el-col>
                <el-col :span="12">
                    <label>旅行目的地</label>
                    {{ destination }}
                </el-col>
                <el-col :span="12">
                    <label>出行方式</label>
                    {{ ['其他出行方式','飞机团'][delay] }}
                </el-col>
            </el-row>
            <template v-if="tabName == 'withTxt'">
                <el-row
                    class="ylwrp vis-person"
                    :gutter="16"
                    v-for="(item, index) in visitPersonArr"
                    :key="'visit' + index"
                >
                    <el-col :span="12">
                        <label>被保险人姓名 {{ index + 1 }}</label>
                        {{ item.InsurancePerson_Name }}
                    </el-col>
                    <el-col :span="12">
                        <label>被保险人证件 {{ index + 1 }}</label>
                        {{ item.InsurancePerson_CertNo }}
                    </el-col>
                </el-row>
            </template>
            <template v-else-if="tabName == 'withPass'">
                <el-row
                    class="ylwrp vis-person"
                    :gutter="16"
                    v-for="(item, index) in outPersonArr"
                    :key="'out' + index"
                >
                    <el-col :span="6">
                        <label>被保险人姓名 {{ index + 1 }}</label>
                        {{ item.InsurancePerson_Name }}
                    </el-col>
                    <el-col :span="6">
                        <label>被保险人证件 {{ index + 1 }}</label>
                        {{ item.InsurancePerson_CertNo }}
                    </el-col>
                    <el-col :span="6">
                        <label>被保险人性别 {{ index + 1 }}</label>
                        {{ item.InsurancePerson_Gender }}
                    </el-col>
                    <el-col :span="6">
                        <label>被保险人生日 {{ index + 1 }}</label>
                        {{ item.InsurancePerson_Birthday }}
                    </el-col>
                </el-row>
            </template>
            <template v-else>
                <el-row class="ylwrp vis-person" :gutter="16">
                    <el-col>
                        <label>被保险人数</label>
                        {{ totalperson }}
                    </el-col>
                    <el-col style="text-align: center;">
                        <img style="width: 90%;" :src="personimage" alt srcset />
                    </el-col>
                </el-row>
            </template>
            <span slot="footer">
                <el-button @click="dilogVis = false">取消</el-button>
                <el-button type="primary" @click="dilogVis = false">好的</el-button>
            </span>
        </el-dialog>
        <el-dialog title="提交确认" :visible.sync="beforeSend" width="36%">
            <p>投保期限：{{ starttime + ' ' + logotime }} 至 {{ finalDate + ' 23:59:59' }}</p>
            <p>天数：{{ day }} 天</p>
            <p>人数：{{ personCount }} 人</p>
            <p>总价：{{ Policy_IsEvery == 1 ? (Policy_Money * day * personCount).toFixed(2) : (Policy_Money * personCount).toFixed(2) }} 元</p>
            <span slot="footer">
                <el-button type="primary" @click="sendFormData(true)">确认提交</el-button>
                <el-button @click="beforeSend = false">取消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="智能识别" :visible.sync="reviewSee">
            <el-input :rows="7" type="textarea" placeholder="请输入需要智能识别的内容" v-model="reviewText"></el-input>
            <span slot="footer">
                <el-button type="primary" @click="sendReviewData(true)">确认提交</el-button>
                <el-button @click="reviewSee = false">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: "",
            visitPersonArr: [
                {
                    InsurancePerson_Name: "",
                    InsurancePerson_CertNo: "",
                },
            ],
            total: 0,
            Policy_IsEvery: 0,
            Policy_Money: 0,
            outPersonArr: [
                {
                    InsurancePerson_Name: "",
                    InsurancePerson_CertNo: "",
                    InsurancePerson_Gender: "",
                    InsurancePerson_Birthday: "",
                },
            ],
            companyid: 0,
            productsName: ["境内", "境外"],
            productsName1: [
                "境内(普通)",
                "境外(普通)",
                "境内(高端)",
                "境外(高端)",
                "境内(普通含延误险)",
                "境外(普通含延误险)",
                "境内(高端含延误险)",
                "境外(高端含延误险)",
            ],
            Policy_Delay: 0,
            personimage: "",
            totalperson: 0,
            personCount: 0,
            guide: "",
            operator: "",
            finalDate: "",
            starttime: "",
            day: "",
            teamnumber: "",
            Policy_Name: "",
            policyid: "",
            policyItem: "",
            reviewSee: false,
            pass1: "",
            pass2: "",
            pass3: "",
            pass4: "",
            pass5: "",
            sendVal: true,
            quality: "",
            logotime: "",
            destination: "",
            delay: "",
            dilogVis: false,
            beforeSend: false,
            onceClick: false,
            tabName: "withTxt",
            ProductList: [],
            errArr: [],
            JDNames: [],
            reviewText: "",
            reviewType: "",
        };
    },
    mounted() {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.companyid = sessionStorage.getItem("companyid");
        this.TravelAgency_ID = userInfo.TravelAgency_ID;
        this.name = userInfo.TravelAgency_Name;
        this.getJDNames();
    },
    methods: {
        add0(num) {
            return num < 10 ? "0" + num : num;
        },
        reviewBD(type) {
            this.reviewType = type;
            this.reviewSee = true;
        },
        sendReviewData() {
            let url = "";
            if (this.reviewType == "withTxt") {
                url =
                    "/AdminTravel/InsuranceTravelPerson.ashx?type=autorecognition";
            } else {
                url =
                    "/AdminTravel/InsuranceTravelPerson.ashx?type=autorecognitionpassport";
            }
            this.ipost(
                url,
                {
                    tmp: this.reviewText,
                },
                (res) => {
                    this.reviewSee = false;

                    if (res.list.length == 0) {
                        this.$message({
                            message: "未识别到有效信息",
                            type: "warning",
                        });
                        return;
                    }

                    if (this.reviewType == "withTxt") {
                        let tempArr = res.list.map((item) => {
                            return {
                                InsurancePerson_Name: item.InsurancePerson_Name,
                                InsurancePerson_CertNo:
                                    item.InsurancePerson_CertNo.trim(),
                                check: false,
                            };
                        });
                        if (
                            this.visitPersonArr.length == 1 &&
                            this.visitPersonArr[0].InsurancePerson_Name == "" &&
                            this.visitPersonArr[0].InsurancePerson_CertNo == ""
                        ) {
                            this.visitPersonArr = tempArr;
                        } else {
                            this.visitPersonArr =
                                this.visitPersonArr.concat(tempArr);
                        }
                    } else {
                        let tempArr = res.list.map((item) => {
                            return {
                                InsurancePerson_Name: item.InsurancePerson_Name,
                                InsurancePerson_CertNo:
                                    item.InsurancePerson_CertNo.trim(),
                                InsurancePerson_Birthday:
                                    item.InsurancePerson_Birthday,
                                InsurancePerson_Gender:
                                    item.InsurancePerson_Gender,
                                check: false,
                            };
                        });
                        if (
                            this.outPersonArr.length == 1 &&
                            this.outPersonArr[0].InsurancePerson_Name == "" &&
                            this.outPersonArr[0].InsurancePerson_CertNo == "" &&
                            this.outPersonArr[0].InsurancePerson_Gender == "" &&
                            this.outPersonArr[0].InsurancePerson_Birthday == ""
                        ) {
                            this.outPersonArr = tempArr;
                        } else {
                            this.outPersonArr =
                                this.outPersonArr.concat(tempArr);
                        }
                    }
                }
            );
        },
        checkboxChange(val, indx) {
            let arrName =
                this.tabName == "withTxt" ? "visitPersonArr" : "outPersonArr";
            if (val) {
                this[arrName][indx].check = true;
            } else {
                this[arrName][indx].check = false;
            }
        },
        deleteCheck() {
            let tempArr = [];
            if (this.tabName == "withTxt") {
                tempArr = this.visitPersonArr.filter((item) => {
                    if (item.check == false || item.check == undefined)
                        return true;
                });

                this.visitPersonArr = [];
                this.visitPersonArr = tempArr;
                console.log(this.visitPersonArr);
            } else {
                tempArr = this.outPersonArr = this.outPersonArr.filter(
                    (item) => {
                        if (item.check == false || item.check == undefined)
                            return true;
                    }
                );

                this.outPersonArr = [];
                this.outPersonArr = tempArr;
                console.log(this.outPersonArr);
            }
        },
        deleteAll() {
            if (this.tabName == "withTxt") {
                this.visitPersonArr = [];
            } else {
                this.outPersonArr = [];
            }
        },
        getJDNames() {
            this.ipost(
                "/AdminTravel/InsuranceTravel.ashx?type=recentoperator",
                null,
                (res) => {
                    this.JDNames = res.list;
                    //                    console.log("JdName",this.JDNames)
                }
            );
        },
        nameSearch(qs, cb) {
            let tempArr = this.JDNames.map((item) => {
                return {
                    name: item.Insurance_Operator,
                    value: item.Insurance_Operator,
                };
            });
            cb(tempArr);
        },
        querySearch(qs, cb) {
            cb([
                { name: "男", value: "男" },
                { name: "女", value: "女" },
            ]);
        },
        switchTab(e) {
            console.log("switch tab name:", e.name);
            this.tabName = e.name;
        },
        insuraceModel(n) {
            let temp = "/AdminTravel/travelperson.xlsx";
            if (n == 1) {
                temp = "/AdminTravel/travelpersonpassport.xlsx";
            }
            window.open(temp, "_blank");
        },
        setImgUrl(res) {
            this.personimage = res.url;
            console.log("personimage", this.personimage);
        },
        insertPersons(res) {
            if (res.url !== "") {
                this.ipost(
                    "/AdminTravel/InsuranceTravelPerson.ashx?type=import",
                    {
                        url: res.url,
                    },
                    (res) => {
                        if (this.tabName == "withTxt") {
                            let tempArr = res.list.map((item) => {
                                return {
                                    InsurancePerson_Name:
                                        item.InsurancePerson_Name,
                                    InsurancePerson_CertNo:
                                        item.InsurancePerson_CertNo.trim(),
                                    check: false,
                                };
                            });
                            if (
                                this.visitPersonArr.length == 1 &&
                                this.visitPersonArr[0].InsurancePerson_Name ==
                                    "" &&
                                this.visitPersonArr[0].InsurancePerson_CertNo ==
                                    ""
                            ) {
                                this.visitPersonArr = tempArr;
                            } else {
                                this.visitPersonArr =
                                    this.visitPersonArr.concat(tempArr);
                            }
                        } else {
                            let tempArr = res.list.map((item) => {
                                return {
                                    InsurancePerson_Name:
                                        item.InsurancePerson_Name,
                                    InsurancePerson_CertNo:
                                        item.InsurancePerson_CertNo.trim(),
                                    InsurancePerson_Birthday:
                                        item.InsurancePerson_Birthday,
                                    InsurancePerson_Gender:
                                        item.InsurancePerson_Gender,
                                    check: false,
                                };
                            });
                            if (
                                this.outPersonArr.length == 1 &&
                                this.outPersonArr[0].InsurancePerson_Name ==
                                    "" &&
                                this.outPersonArr[0].InsurancePerson_CertNo ==
                                    "" &&
                                this.outPersonArr[0].InsurancePerson_Gender ==
                                    "" &&
                                this.outPersonArr[0].InsurancePerson_Birthday ==
                                    ""
                            ) {
                                this.outPersonArr = tempArr;
                            } else {
                                this.outPersonArr =
                                    this.outPersonArr.concat(tempArr);
                            }
                        }
                    }
                );
            }
        },
        sendFormData(bool) {
            console.log(typeof bool);
            if (this.checkFormData()) {
                let tempUrl = "";
                let postData = {
                    guide: this.guide,
                    operator: this.operator,
                    name: this.name,
                    starttime: this.starttime,
                    day: this.day,
                    teamnumber: this.teamnumber,
                    policyid: this.policyid,
                    destination: this.destination,
                    delay: this.delay,
                };
                if (this.tabName == "withTxt") {
                    tempUrl =
                        "/AdminTravel/InsuranceTravel.ashx?type=insertparambyagency";
                    postData.personlist = JSON.stringify(this.visitPersonArr);
                    this.personCount = this.visitPersonArr.length;
                } else if (this.tabName == "withPass") {
                    tempUrl =
                        "/AdminTravel/InsuranceTravel.ashx?type=insertpassportparambyagency";
                    postData.personlist = JSON.stringify(this.outPersonArr);
                    this.personCount = this.outPersonArr.length;
                } else {
                    tempUrl =
                        "/AdminTravel/InsuranceTravel.ashx?type=insertpersonimagebyagency";
                    postData.personimage = this.personimage;
                    postData.totalperson = this.totalperson;
                }

                if (bool !== true && this.companyid == 46) {
                    this.beforeSend = true;
                    return false;
                }

                if (this.sendVal) {
                    this.sendVal = false;
                    this.ipost(
                        tempUrl,
                        postData,
                        (res) => {
                            let tempStr = "";
                            res.policylist.forEach((item) => {
                                tempStr +=
                                    "<p>" +
                                    item.Policy_Name +
                                    " 有 " +
                                    item.PolicyTravel_PersonList.length +
                                    " 人 " +
                                    item.PolicyTravel_MoneyTotal +
                                    "元</p>";
                            });
                            this.sendVal = true;
                            this.beforeSend = false;
                            this.$alert(tempStr, "下单成功", {
                                dangerouslyUseHTMLString: true,
                            });
                            this.resetForm();
                            console.log("res:", res);
                            // Location.reload(true)
                        },
                        () => {
                            this.sendVal = true;
                        }
                    );
                }
            }
        },
        setProudctName(evt) {
            let current = this.ProductList.find(
                (item) => item.Policy_Name == this.policyItem
            );
            this.policyid = current.Policy_ID;
            this.Policy_IsEvery = current.Policy_IsEvery;
            this.Policy_Money = current.Policy_Money;
            console.log("policyid:", current.Policy_ID);

            this.Policy_Name = this.policyItem;
        },
        resetForm() {
            this.visitPersonArr.length = 1;
            this.outPersonArr.length = 1;
            this.visitPersonArr[0]["InsurancePerson_Name"] = "";
            this.visitPersonArr[0]["InsurancePerson_CertNo"] = "";
            this.visitPersonArr[0]["check"] = false;
            this.outPersonArr[0]["InsurancePerson_Name"] = "";
            this.outPersonArr[0]["InsurancePerson_CertNo"] = "";
            this.outPersonArr[0]["InsurancePerson_Gender"] = "";
            this.outPersonArr[0]["InsurancePerson_Birthday"] = "";
            this.outPersonArr[0]["check"] = false;
            this.Policy_Delay = 0;
            this.quality = "";
            this.policyItem = "";
            this.personlist = "";
            this.guide = "";
            this.starttime = "";
            this.day = "";
            this.operator = "";
            this.teamnumber = "";
            this.policyid = "";
            this.destination = "";
            this.delay = "";
            this.personimage = "";
            this.totalperson = 0;
        },
        dateCheck(e) {
            if (e) {
                let today = new Date();

                let nowMilitime = Date.parse(
                    `${today.getFullYear()}-${
                        today.getMonth() + 1
                    }-${today.getDate()} 12:00:00`
                );

                let nowTimeNum = Date.now();

                // console.log("nowMilitime",nowMilitime)
                // console.log("nowTimeNum",nowTimeNum)
                let currentDayStr = `${today.getFullYear()}-${this.add0(
                    today.getMonth() + 1
                )}-${this.add0(today.getDate())}`;

                console.log(
                    "starttime",
                    this.starttime,
                    "currentDayStr",
                    currentDayStr
                );

                if (this.starttime == currentDayStr) {
                    if (this.TravelAgency_ID == 233) {
                        this.logotime = new Date(
                            nowTimeNum + 30 * 60 * 1000
                        ).toLocaleTimeString("zh-CN");
                    } else {
                        this.logotime = new Date(
                            nowTimeNum + 120 * 60 * 1000
                        ).toLocaleTimeString("zh-CN");
                    }
                } else {
                    this.logotime = "00:00:00";
                }

                console.log("logotime", this.logotime);

                if (
                    this.companyid != 46 &&
                    nowTimeNum > nowMilitime &&
                    e == currentDayStr
                ) {
                    this.$message("已过中午12点，不能选当天日期");
                    this.starttime = "";
                    return false;
                }

                let pickTimeNum = Date.parse(e + " 23:59:59");

                if (nowTimeNum > pickTimeNum) {
                    this.$message("开始日期不能小于当天日期");
                    this.starttime = "";
                    return false;
                }

                this.computeDate(Date.parse(this.starttime), this.day);
            }
        },
        dayChange(e) {
            console.log(e);
            if (typeof Number(e) == "number") {
                this.day = Math.floor(e);

                this.computeDate(Date.parse(this.starttime), this.day);

                // 置空下拉选择
                this.quality = "";
                this.policyItem = "";
                this.policyid = "";
                this.ProductList.length = 0;
            } else {
                this.$message("请输入数字");
            }
        },
        computeDate(dateMiliTime, num) {
            if (dateMiliTime == "" || num == "" || num == 0) {
                return;
            }

            let miliTime = dateMiliTime + (num - 1) * 1000 * 60 * 60 * 24;

            // console.log(miliTime,new Date(miliTime).toLocaleDateString('zh-CN'))

            this.finalDate = `${new Date(miliTime).getFullYear()}-${this.add0(
                new Date(miliTime).getMonth() + 1
            )}-${this.add0(new Date(miliTime).getDate())}`;
        },
        getPolicyList() {
            this.ProductList.length = 0;
            this.policyItem = "";
            this.policyid = "";
            this.ipost(
                "/AdminTravel/PolicyTravel.ashx?type=selectbyagency",
                {
                    pageindex: 1,
                    pagesize: 666,
                    day: this.day,
                    quality: this.quality,
                },
                (res) => {
                    this.ProductList = res.list;
                    this.Policy_Money = res.list[0].Policy_Money;
                    this.Policy_IsEvery = res.list[0].Policy_IsEvery;
                    this.Policy_Name = this.policyItem =
                        res.list[0].Policy_Name || "";
                    this.policyid = res.list[0].Policy_ID || "";
                    // this.total = res.count
                    console.log("产品列表 res:", res);
                }
            );
        },
        review() {
            if (this.checkFormData()) {
                this.dilogVis = true;
            }
        },
        addPerson() {
            this.visitPersonArr.push({
                InsurancePerson_Name: "",
                InsurancePerson_CertNo: "",
                check: false,
            });
        },
        confirmPerson() {
            this.errArr = [];

            for (let i = 0; i < this.visitPersonArr.length; i++) {
                let current = this.visitPersonArr[i];

                if (
                    current.InsurancePerson_Name == "" ||
                    current.InsurancePerson_CertNo == "" ||
                    current.InsurancePerson_CertNo.trim().length != 18
                ) {
                    this.errArr.push(current.InsurancePerson_CertNo);
                }
            }

            if (this.errArr.length > 0) {
                this.$message("个别人员信息有误，请检查");

                let firstIndx = this.visitPersonArr.findIndex(
                    (item) => item.InsurancePerson_CertNo == this.errArr[0]
                );

                let aimHeight = 56 + 83 * (firstIndx + 1);

                console.log("aimHeight", aimHeight);
                document
                    .querySelector(".person-list-wrp")
                    .scrollTo(0, aimHeight);

                return false;
            }

            this.ipost(
                "/AdminTravel/InsuranceTravel.ashx?type=checkpersonlist",
                {
                    personlist: JSON.stringify(this.visitPersonArr),
                },
                (res) => {
                    this.$message("校验全部通过...");
                },
                (res) => {
                    this.errArr = res.list.map(
                        (item) => item.InsurancePerson_CertNo
                    );
                    console.log("errArr", this.errArr);

                    let firstIndx = this.visitPersonArr.findIndex(
                        (item) => item.InsurancePerson_CertNo == this.errArr[0]
                    );

                    let aimHeight = 56 + 83 * (firstIndx + 1);

                    console.log("aimHeight", aimHeight);
                    document
                        .querySelector(".person-list-wrp")
                        .scrollTo(0, aimHeight);
                }
            );
        },
        addOPerson() {
            this.outPersonArr.push({
                InsurancePerson_Name: "",
                InsurancePerson_CertNo: "",
                InsurancePerson_Gender: "",
                InsurancePerson_Birthday: "",
                check: false,
            });
        },
        sendDelete(index, n) {
            if (n == 1) {
                this.outPersonArr.splice(index, 1);
            } else {
                let errIndx = this.errArr.findIndex(
                    (item) =>
                        item ==
                        this.visitPersonArr[index].InsurancePerson_CertNo
                );
                if (errIndx != -1) {
                    this.errArr.splice(errIndx, 1);
                }
                this.visitPersonArr.splice(index, 1);
            }
        },
        checkFormData() {
            // 如果所属为温州的旅游公司,那么要填写出行方式
            if (this.companyid == 46 && this.delay === "") {
                this.$message("请填写出行方式")
                return false
            }

            if (this.policyid == "") {
                this.$message("请选择产品");
                return false;
            }

            if (this.starttime == "") {
                this.$message("请填写保险日期");
                return false;
            }

            if (this.day == "" || this.day == 0) {
                this.$message("请填写保险天数");
                return false;
            }

            if (this.destination == "") {
                this.$message("请填写旅行目的地");
                return false;
            }

            if (this.tabName == "withTxt") {
                for (let i = 0; i < this.visitPersonArr.length; i++) {
                    let current = this.visitPersonArr[i];

                    if (current.InsurancePerson_Name == "") {
                        this.$message(`被保险人${i + 1}的名字不能为空`);
                        return false;
                    }

                    if (current.InsurancePerson_CertNo == "") {
                        this.$message(`被保险人${i + 1}的身份证号不能为空`);
                        return false;
                    }

                    if (
                        /^(([\u2E80-\uFE4F](?![\u3000-\u303F])))+(·)?( )*(([\u2E80-\uFE4F](?![\u3000-\u303F])))+$/.test(
                            current.InsurancePerson_Name.trim()
                        ) == false
                    ) {
                        this.$message(`请正确填写被保险人${i + 1}的中文名字`);
                        return false;
                    }
                    // if (current.InsurancePerson_CertNo.length !== 18) {
                    //     this.$message(`请正确填写被保险人${i + 1}的身份证信息`)
                    //     return false
                    // }
                }
            } else if (this.tabName == "withPass") {
                for (let i = 0; i < this.outPersonArr.length; i++) {
                    let current = this.outPersonArr[i];
                    if (
                        current.InsurancePerson_Name == "" ||
                        current.InsurancePerson_CertNo == ""
                    ) {
                        this.$message(`请正确填写被保险人${i + 1}的信息`);
                        return false;
                    }
                    if (
                        (this.companyid == 12 || this.companyid == 46) &&
                        (current.InsurancePerson_Gender == "" ||
                            current.InsurancePerson_Birthday == "")
                    ) {
                        this.$message(
                            `请正确填写被保险人${i + 1}的性别和生日信息`
                        );
                        return false;
                    }
                }
            } else {
                if (this.totalperson == 0) {
                    this.$message(`请先填写被保险人数量`);
                    return false;
                }

                if (this.personimage == "") {
                    this.$message(`请先上传被保险人图片`);
                    return false;
                }
            }

            return true;
        },
    },
};
</script>

<style scoped>
.check-box-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
}
.check-box-wrp .el-checkbox {
    margin-top: 40px;
}
.form-input-wrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.form-input-wrp .el-input {
    width: calc(100% - 120px);
}

.dateSelect {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.dateSelect p {
    color: #999;
    font-size: 14px;
    text-decoration: 0 0 2px #ccc;
    line-height: 37px;
    padding: 0 6px;
    margin: 0;
}

.insurance-btn-wrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.insurance-btn-wrp .el-button {
    margin-right: 7px;
}

.lbt {
    text-align: center;
    font-size: 13px;
}

.page-cont {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.form-ipt .el-col {
    display: flex;
    margin-bottom: 12px;
}

.form-ipt .el-col label {
    width: 77px;
    font-size: 14px;
}

.infoCheck {
    width: 97%;
    padding: 15px;
    box-sizing: border-box;
    margin: 10px auto;
    border-radius: 6px;
    box-shadow: 0 0 12px #dedede;
    background-color: #fefefe;
}

.person-info {
    height: auto;
    display: flex;
    flex-direction: column;
}
.person-list-wrp {
    max-height: calc(100vh - 385px);
    overflow: hidden;
    overflow-y: auto;
}

.infoCheck h2 {
    margin: 6px 0;
}

.top-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.top-btns .el-button {
    margin-left: 8px;
}

.infoCheck label {
    line-height: 2.7;
}

.destwrp {
    line-height: 40px;
    text-align: center;
}

.btnswrp {
    margin: 5px auto;
    text-align: right;
}

.top-row {
    margin: 5px 0;
}

.top-row h2 {
    margin: 0;
}

.btn-delt .el-button {
    margin-top: 40px;
}

.ylwrp {
    font-size: 16px;
    font-weight: bold;
}

.ylwrp label {
    line-height: 37px;
    margin-right: 12px;
    font-size: 13px;
    color: #a1a1a1;
}

.vis-person {
    margin-top: 17px;
    border-top: 1px dashed #ccc;
}

.err .el-input,
.err .el-input .el-input__inner {
    border: 1px solid #f00 !important;
    background-color: #f00 !important;
    border-radius: 4px;
}

em {
    font-style: normal;
    color: tomato;
}
</style>
